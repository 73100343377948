import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchConversations(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/bot/users/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addConversation(ctx, {payload}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/bot/conversations/`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateConversation(ctx, {payload}) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/bot/conversations/${payload.id}/`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getChat(ctx, { convoId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/bot/conversations/${convoId}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, { payload }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/bot/chats/`,  payload )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBots(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/bot/chatbots/` )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchConversationCategories(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/bot/categories/` )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
