<template>
  <div
    class="chat-contact d-flex align-center pa-3 cursor-pointer rounded-lg"
    :class="{'bg-gradient-primary active-chat-contact': isActive}"
    v-on="$listeners"
  >
    <v-avatar
      size="38"
      icon
      class="v-avatar-light-bg mr-1"
    >
      <v-icon
          size="25">
        {{resolveBotIconVariant(conversation.bot.name)}}
      </v-icon>
    </v-avatar>
    <div class="flex-grow-1 overflow-hidden">
      <p class="mb-0 font-weight-medium text--primary">
        {{ conversation.bot.name }}
      </p>
      <p class="text--disabled mb-0 text-truncate">
        {{ conversation.title }}
      </p>
    </div>
    <div
      class="chat-meta d-flex flex-column align-self-stretch align-end"
    >
      <p class="text-no-wrap mb-0">
        {{ formatDateToMonthShort(conversation.updated_at, { hour: 'numeric', minute: 'numeric' }) }}
      </p>
      
      
      
      <!-- <v-badge
        v-if="user.chat.unseenMsgs"
        color="error"
        inline
        :content="user.chat.unseenMsgs"
        class="number-badge"
      >
      </v-badge> -->
    </div>
  </div>
</template>

<script>
import { formatDateToMonthShort, avatarText } from '@core/utils/filter'
import useChat from './useChat'

export default {
  props: {
    conversation: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant, resolveBotIconVariant } = useChat()

    return { 
      formatDateToMonthShort, 
      resolveAvatarBadgeVariant, 
      avatarText,
      resolveBotIconVariant,
      }
  },
}
</script>

<style>
</style>
