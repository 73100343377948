<template>
  <div class="chat-left-sidebar h-full">
    <div class="d-flex align-center pa-4">
      <v-badge
        bottom
        overlap
        dot
        bordered
        color="success"
        offset-x="11"
        offset-y="11"
        class="me-3 user-status-badge"
      >
        <v-avatar
          size="2.375rem"
          class="cursor-pointer"
          :color="stringToHslColor(profileUserMinimalData.name) || primary"
        >
            <span class="white--text">{{avatarText(profileUserMinimalData.name)}}</span>
        </v-avatar>
      </v-badge>
      <v-text-field
        v-model="searchQuery"
        placeholder="search..."
        hide-details
        outlined
        rounded
        dense
        class="chat-search"
        :prepend-inner-icon="icons.mdiMagnify"
      ></v-text-field>
      <v-icon
        class="d-inline-flex d-md-none ms-1"
        @click="$emit('close-left-sidebar')"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </div>

    <v-divider></v-divider>

    <perfect-scrollbar
      :options="perfectScrollbarOptions"
      class="ps-chat-left-sidebar pt-7 pb-3 px-3"
    >
      <!-- Chats -->
      <div class="d-flex align-center justify-space-between pb-2">
      <span class="font-weight-medium text-xl primary--text">
        Conversations
      </span>
      <v-btn icon color="secondary" @click="$emit('add-convo')">
        <v-icon>
          {{ icons.mdiPlus }}
        </v-icon>
      </v-btn>
      </div>
      <div class="text-center" v-if="!convoHistoryLoaded">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <chat-contact
        v-for="conversation in filteredChatsContacts"
        :key="`chat-${conversation.id}`"
        :conversation="conversation"
        :is-active="activeChatContactId === conversation.id"
        :class="{'bg-gradient-primary active-chat-contact': activeChatContactId === conversation.id}"
        @click="$emit('open-convo', conversation.id)"
        
      />

    </perfect-scrollbar>
  </div>
</template>

<script>
import { mdiMagnify, mdiClose, mdiPlus } from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { stringToHslColor } from '@core/utils'
import ChatContact from './ChatContact.vue'
import useChat from './useChat'

export default {
  components: {
    PerfectScrollbar,
    ChatContact,
  },
  props: {
    chatsContacts: {
      type: Array,
      required: true,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    profileUserMinimalData: {
      type: Object,
      required: true,
    },
    convoHistoryLoaded: {
      type: Boolean,
      required: false,
    }
  },
  setup(props) {
    const { resolveAvatarBadgeVariant } = useChat()

    const localChatContacts = ref([])
    const resetLocalChatContacts = () => {
      localChatContacts.value = JSON.parse(JSON.stringify(props.chatsContacts))
    }

    // Search Query
    const searchQuery = ref('')

    const searchFilterFunction = conversation => {
      return conversation.bot.name.toLowerCase().includes(searchQuery.value.toLowerCase()) || conversation.title.toLowerCase().includes(searchQuery.value.toLowerCase().split())
    }
    const filteredChatsContacts = computed(() => localChatContacts.value.filter(searchFilterFunction))

    // Perfect scrollbar options
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      wheelSpeed: 0.7,
    }

    watch(
      () => props.chatsContacts,
      () => {
        resetLocalChatContacts()
      }
    )

    onMounted(() => {
        resetLocalChatContacts()
    })

    return {
      // Use Chat
      resolveAvatarBadgeVariant,
      localChatContacts,

      // Search
      searchQuery,
      filteredChatsContacts,

      // Perfect Scrollbar options
      perfectScrollbarOptions,

      // Filter
      avatarText,
      stringToHslColor,

      // Icons
      icons: {
        mdiMagnify,
        mdiClose,
        mdiPlus
      },
    }
  },
}
</script>
