<template>
    
    <div id="chat-welcome">
        <v-row >
            <v-col
                cols="12"
                md="12">
                <v-card flat color="transparent" >
                    <v-card-title
                        class="justify-center pa-0">
                        ChatGPT
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                md="4"  v-for="item in welcome" :key="item.title">
                <v-card flat class="mb-4" width="250" color="transparent">
                    <v-card-title class="d-flex align-center justify-center flex-grow-1 flex-column py-0">
                        <v-avatar>
                            <v-icon
                                size="24"
                                class="rounded-0 text--primary"
                            >
                                {{ item.icon }}
                            </v-icon>
                        </v-avatar>
                        {{ item.title }}
                    </v-card-title>
                </v-card>
                
                <v-card class="mb-4" width="250" v-for="card, index in item.list" :key="index" >
                    <v-card-text>
                        {{ card }}
                    </v-card-text>
                </v-card>
            </v-col>
            
        </v-row>
        <v-row class="mb-3 ">
            <v-col cols="12" md="12" class="pt-0">
                <v-card flat color="transparent" >
                    <v-card-text class="pa-0">
                        *As of March 1st, 2023, openAI/ChatGPT retains your API data for 30 days but no longer uses your data sent via the API to improve their models.*
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
    
</template>

<script>
import { ref, onUnmounted, nextTick } from '@vue/composition-api'
import { mdiMessageTextOutline,
        mdiLightningBoltOutline,
        mdiAlertOutline  } from '@mdi/js'

export default ({
    setup() {

        const welcome = ref({
            examples: {
                title: "Examples",
                icon: mdiMessageTextOutline,
                list: [
                "Can you write a market research questionnaire to measure the impact sustainability can have on packaging design for CPG companies?",
                "Can you help defining semantics from open-ended answers for cpg questionnaire?",
                "what's the difference between a good and a bad pack design?"
                ]}
            ,
            capabilities: {
                title: "Capabilities",
                icon: mdiLightningBoltOutline,
                list: [
                    "Remembers what user said earlier in the conversation",
                    "Allows user to provide follow-up corrections",
                    "Trained to decline inappropriate requests"
                ]
            },
            limitations: {
                title: "Limitations",
                icon: mdiAlertOutline,
                list: [
                    "May occasionally generate incorrect information",
                    "May occasionally produce harmful instructions or biased content",
                    "Limited knowledge of world and events after 2021"
                ]
            }
        })

        return {
            welcome,
            icons: {
                mdiMessageTextOutline,
                mdiLightningBoltOutline,
                mdiAlertOutline
            }
        }
        
    },
})
</script>
