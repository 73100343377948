<template>
    <v-dialog
    v-model="isConvoDialogOpen"
    max-width="500px"
    @click:outside="$emit('update:is-convo-dialog-open',false)"
  >
    <v-card class="project-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Create a new conversation
      </v-card-title>

      <v-card-text class="mt-5">
        <v-form 
          class="multi-col-validation"
          ref="convoForm"
        >
          <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                  v-model="conversation.title"
                  label="Title*"
                  dense
                  outlined
                  :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <v-autocomplete
                  v-model="conversation.category"
                  :loading="conversationCategoryLoading"
                  :items="conversationCategoryOptions"
                  item-text="label"
                  item-value="value"
                  label="Category"
                  hint="Choose the relevant Category"
                  persistent-hint
                  cache-items
                  outlined
                  dense
                  small-chips
                  hide-details="auto"
                  placeholder="Category"
                  :rules="[validators.required]"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <v-autocomplete
                  v-model="conversation.bot"
                  :loading="chatBotLoading"
                  :items="chatBotOptions"
                  item-text="label"
                  item-value="value"
                  label="ChatBot"
                  hint="Choose the relevant ChatBot"
                  persistent-hint
                  cache-items
                  outlined
                  dense
                  small-chips
                  hide-details="auto"
                  placeholder="ChatBot"
                  :rules="[validators.required]"
              ></v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                :loading="chatBotCreationLoading"
                :disabled="chatBotCreationLoading"
                @click.prevent="onSubmit"
              >
                Create
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-convo-dialog-open',false)"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import {
  required,
} from '@core/utils/validation'
import useChat from './useChat'

export default ({
    props: {
        isConvoDialogOpen: {
            type:Boolean,
            required:true
        }
    },
    setup(props, {emit}) {
        const conversation = ref({})
        const convoForm = ref(null)
        const user = ref(JSON.parse(localStorage.getItem('user')))

        const {
            fetchBots,
            chatBotLoading,
            chatBotOptions,
            chatBotCreationLoading,

            fetchConversationCategories,
            conversationCategoryLoading,
            conversationCategoryOptions
        } = useChat()

        const createConvo = () =>{
        }

         // on form submit
        const onSubmit = async () => {
        const isStepValid = convoForm.value.validate()
        
        if (isStepValid) {
            const localConvo = JSON.parse(JSON.stringify(conversation.value))
            localConvo.user = user.value.azure_ad_id
            emit('add-convo', localConvo)
        }

        // const response = await updateProject(projectDataLocal.value).then( () => {
        //   emit('update:is-project-attributes-dialog-open', false)
        // })
        
        // console.log(response)
        // if (response){
            
        // }
        }

        watch(
          () => props.isConvoDialogOpen,
          () => {
            // conversation.value = {}
            if (convoForm.value) convoForm.value.reset()
          }
        )

        onMounted(() => {
            fetchBots()
            fetchConversationCategories()
        })

        return {
            conversation,
            chatBotLoading,
            chatBotOptions,
            chatBotCreationLoading,
            conversationCategoryLoading,
            conversationCategoryOptions,
            onSubmit,
            convoForm,

            validators: {
                required
            }
        }
    },
})
</script>
