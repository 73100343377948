
import { ref, watch, getCurrentInstance, computed } from '@vue/composition-api'
import store from '@/store'
import { formatOptions } from '@core/utils'
import { foundryRequest } from '@/config/authConfig';

import {
  mdiRobotOutline,
  mdiRobotAngryOutline,
  } from '@mdi/js'

export default () => {

  const vm = getCurrentInstance().proxy
  const chatBotOptions = ref([])
  const chatBotLoading = ref(false)
  const chatBotCreationLoading = ref(false)
  const convoSystemLoading = ref(false)

  const conversationCategoryOptions = ref([])
  const conversationCategoryLoading = ref(false)

  const resolveAvatarBadgeVariant = status => {
    if (status === 'online') return 'success'
    if (status === 'busy') return 'error'
    if (status === 'away') return 'warning'

    return 'secondary'
  }
  
  const resolveBotIconVariant = name => {
    if (name === 'ChatGPT') return mdiRobotOutline
    return mdiRobotAngryOutline 
  }

  

  const fetchBots = async () => {
    const token = await vm.$msal.getTokenPopup(foundryRequest)
    chatBotLoading.value = true
    store.dispatch('app-chat/fetchBots').then(response => {
        const { data } = response
        chatBotOptions.value = formatOptions(data)
        chatBotLoading.value = false
    })
  }


  const fetchConversationCategories = async () => {
    const token = await vm.$msal.getTokenPopup(foundryRequest)
    conversationCategoryLoading.value = true
    store.dispatch('app-chat/fetchConversationCategories').then(response => {
        const { data } = response
        conversationCategoryOptions.value = formatOptions(data)
        conversationCategoryLoading.value = false
    })
  }


  return {
    resolveAvatarBadgeVariant,
    resolveBotIconVariant,
    chatBotOptions,
    chatBotLoading,
    chatBotCreationLoading,
    conversationCategoryOptions,
    conversationCategoryLoading,convoSystemLoading,
    
    fetchBots,
    fetchConversationCategories
  }
}
