<template>
  <div class="chat-log pa-5">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.role+String(index)"
      class="chat-group d-flex align-start"
      :class="[{'flex-row-reverse': msgGrp.role !== formattedChatData.contact.role}, { 'mb-8': formattedChatData.formattedChatLog.length-1 !== index }]"
    >
      <div
        class="chat-avatar"
        :class="msgGrp.role !== formattedChatData.contact.role ? 'ms-4' : 'me-4'"
      >
        <!-- <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="msgGrp.senderId === formattedChatData.contact.id ? formattedChatData.contact.avatar : profileUserAvatar"
        /> -->

        <v-avatar
          size="38"
          :color="stringToHslColor(user.name) || primary"
          v-if="msgGrp.role == 'user'"
        >
            <span class="white--text">{{avatarText(user.name)}}</span>
        </v-avatar>
        <v-avatar
          size="38"
          class="v-avatar-light-bg mr-1"
          v-else
        >
          <v-icon
              size="25">
            {{resolveBotIconVariant(chatData.bot.name)}}
          </v-icon>
        </v-avatar>

      </div>
      <div
        class="chat-body d-inline-flex flex-column"
        :class="msgGrp.role !== formattedChatData.contact.role ? 'align-end' : 'align-start'"
      >
        <p
          v-for="(msgData, msgIndex) in msgGrp.messages"
          :key="msgData.time"
          class="chat-content py-3 px-4 elevation-1"
          :class="[
            msgGrp.role === formattedChatData.contact.role ? 'bg-card chat-left' : 'primary white--text chat-right',
            msgGrp.messages.length-1 !== msgIndex ? 'mb-2' : 'mb-1'
          ]"
          style="white-space: pre-wrap;"
        >{{ msgData.msg }}  
        </p>
        <div :class="{'text-right': msgGrp.role !== formattedChatData.contact.role}">
          <span class="text-xs me-1 text--disabled">{{ formatDate(msgGrp.messages[msgGrp.messages.length-1].time, { hour: 'numeric', minute: 'numeric' }) }}</span>
          <!-- <v-icon
            v-if="msgGrp.senderId !== formattedChatData.contact.id"
            size="16"
            :color="resolveFeedbackIcon(msgGrp.messages[msgGrp.messages.length-1].feedback).color"
          >
            {{ resolveFeedbackIcon(msgGrp.messages[msgGrp.messages.length-1].feedback).icon }}
          </v-icon> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { formatDate, avatarText } from '@core/utils/filter'
import { stringToHslColor } from '@core/utils'
import useChat from './useChat'

import { mdiCheck, mdiCheckAll } from '@mdi/js'

export default {
  props: {
    chatData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const user = ref(JSON.parse(localStorage.getItem('user')))
    const totalTokens = ref(0)
    const { resolveBotIconVariant } = useChat()
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.bot.id,
        avatar: props.chatData.bot.name,
        role: props.chatData.bot.role,
      }
      totalTokens.value = 0
      let chatLog = []
      if (props.chatData.chats) {
        chatLog = props.chatData.chats
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].role : undefined
      let msgGroup = {
        role: chatMessageSenderId,
        messages: [],
      }
      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.role) {
          msgGroup.messages.push({
            msg: msg.content,
            time: msg.created_at,
            feedback: msg.feedback,
          })
        } else {
          chatMessageSenderId = msg.role
          formattedChatLog.push(msgGroup)
          msgGroup = {
            role: msg.role,
            messages: [
              {
                msg: msg.content,
                time: msg.created_at,
                feedback: msg.feedback,
              },
            ],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })
      return {
        formattedChatLog,
        contact
      }
    })

    // Feedback icon
    const resolveFeedbackIcon = feedback => {
      if (feedback.isSeen) return { icon: mdiCheckAll, color: 'success' }
      if (feedback.isDelivered) return { icon: mdiCheckAll, color: null }

      return { icon: mdiCheck, color: null }
    }

    return {
      user,
      formattedChatData,
      avatarText,
      stringToHslColor,
      resolveBotIconVariant,

      // Filter
      formatDate,

      // Feedback Icon
      resolveFeedbackIcon,

      // Icons
      icons: {
        mdiCheck,
        mdiCheckAll,
      },
    }
  },
}
</script>
