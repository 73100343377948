<template>
  <v-card class="max-h-content-container app-chat position-relative overflow-hidden text-sm">
    <!-- Navigation Drawer: Left Sidebar -->
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="374"
      touchless
      :right="$vuetify.rtl"
      mobile-breakpoint="sm"
      :temporary="$vuetify.breakpoint.xsOnly"
      absolute
    >
      <chat-left-sidebar-content
        :chats-contacts="chatsContacts"
        :active-chat-contact-id="activeChat.id ? activeChat.id : null"
        :profile-user-minimal-data="profileUserDataMinimal"
        :convo-history-loaded="convoHistoryLoaded"
        @close-left-sidebar="isLeftSidebarOpen = false"
        @add-convo="convoDialog = true"
        @open-convo="openChatOfConvo"
      ></chat-left-sidebar-content>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="isConvoSystemDrawerActive"
      right
      touchless
      app
      temporary
      width="400"
    >
      <chat-system-sidebar-content
        :isConvoSystemDrawerActive.sync="isConvoSystemDrawerActive"
        :convo="activeChat"
        @update-convo="updateConversation"
      ></chat-system-sidebar-content>
    </v-navigation-drawer>

    <v-dialog
      v-model="isConvoSystemDialog"
      width="450"
    >
      <v-card >
        <v-card-title class="justify-center">
          Do you want to set a system behavior to your conversation?
        </v-card-title>

        <v-card-text class="mt-5">
          
              <v-col
                cols="12"
                class="d-flex justify-center mt-3"
              >
                <v-btn
                  color="primary"
                  class="me-3"
                  type="submit"
                  @click="isConvoSystemDrawerActive=true; isConvoSystemDialog = false"
                >
                  Yes
                </v-btn>

                <v-btn
                  outlined
                  color="secondary"
                  @click.prevent="isConvoSystemDialog = false"
                >
                  No
                </v-btn>
              </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div
      class="chat-content-area h-full d-flex flex-column"
      :style="contentStyles"
    >
      <alert />
      <!-- Active Chat -->

      
      <div class="h-full" v-if="!convoLoaded">
        <v-row >
          <v-col cols="12" md="12">
            <v-card flat color="transparent">
              <v-card-text class="text-center">
                  <v-progress-circular
                    :size="45"
                    indeterminate
                    dense
                    color="error"
                  ></v-progress-circular>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <template v-else>
        
        <div
          v-if="activeChat.chats"
          class="h-full"
        >
          <!-- Navbar -->
          <div
            class="d-flex align-center justify-space-between px-5 py-4"
            style="height:72px"
          >
            <!-- Sidebar Toggler, Avatar & Name -->
            <div class="d-flex align-center">
              <v-btn
                icon
                class="me-2 d-inline-block d-md-none"
              >
                <v-icon
                  v-show="!isLeftSidebarOpen"
                  @click="isLeftSidebarOpen = true"
                >
                  {{ icons.mdiMenu }}
                </v-icon>
              </v-btn>
              <v-avatar
                size="38"
                class="v-avatar-light-bg mr-1"
              >
                <v-icon
                    size="25">
                  {{resolveBotIconVariant(activeChat.bot.name)}}
                </v-icon>
              </v-avatar>
              <div class="d-flex flex-column">
                <p class="mb-0 text--primary font-weight-medium">
                  {{ activeChat.bot.name }}
                </p>
                <span class="text--disabled text-xs">{{ activeChat.bot.source }}</span>
              </div>
            </div>
  
            <!-- Active Chat Actions -->
            <div class="d-flex align-baseline">
              <!-- <v-icon class="me-3 cursor-pointer d-none d-sm-inline-flex">
                {{ icons.mdiMagnify }}
              </v-icon> -->
              <v-icon class="cursor-pointer" @click="isConvoSystemDrawerActive=true">
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </div>
          </div>
  
          <v-divider></v-divider>
  
          <perfect-scrollbar
            ref="refChatLogPS"
            :options="perfectScrollbarOptions"
            class="ps-chat-log h-full"
          >
            <chat-log
              :chat-data="activeChat"
            ></chat-log>
          </perfect-scrollbar>
  
          <v-form
            autocomplete="off"
            @submit.prevent="sendMessage"
          >
            <v-text-field
              :key="activeChat.bot.id"
              v-model="chatInputMessage"
              placeholder="Type your message"
              solo
              class="pb-5 px-5 flex-grow-0 msg-input"
              hide-details
              height="50"
              autofocus
            >
              <template #append>
                <!-- <v-btn icon>
                  <v-icon>{{ icons.mdiMicrophone }}</v-icon>
                </v-btn>
                <v-btn
                  icon
                  class="me-3"
                >
                  <v-icon>
                    {{ icons.mdiAttachment }}
                  </v-icon>
                </v-btn> -->
                <v-btn
                  color="primary"
                  elevation="0"
                  type="submit"
                  :icon="$vuetify.breakpoint.xsOnly"
                  :loading="loading"
                >
                  <template v-if="$vuetify.breakpoint.smAndUp">
                    Send
                  </template>
                  <template v-else>
                    <v-icon>{{ icons.mdiSendOutline }}</v-icon>
                  </template>
                </v-btn>
              </template>
            </v-text-field>
          </v-form>
        </div>
  
        <!-- Start Conversation -->
        <div
          v-else
          class="d-flex align-center justify-center flex-grow-1 flex-column"
        >
        <chat-welcome>
  
        </chat-welcome>
          <!-- <v-avatar
            size="109"
            class="elevation-3 mb-6 bg-card"
          >
            <v-icon
              size="50"
              class="rounded-0 text--primary"
            >
              {{ icons.mdiMessageTextOutline }}
            </v-icon>
          </v-avatar> -->
          <v-btn
            class="mb-0 px-6 py-1 font-weight-medium text-lg elevation-3 rounded-xl text--primary bg-card"
            :class="[{ 'cursor-pointer': true }]"
            @click="startConversation"
          >
            Start Conversation
          </v-btn>
        </div>
        </template>
        
    </div>
    <conversation-dialog
      :isConvoDialogOpen.sync="convoDialog"
      @add-convo="addConversation"
    >

    </conversation-dialog>
    
  </v-card>
</template>

<script>
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
// eslint-disable-next-line object-curly-newline
import {
  mdiMenu,
  mdiMessageTextOutline,
  mdiPhone,
  mdiVideoOutline,
  mdiMagnify,
  mdiDotsVertical,
  mdiAttachment,
  mdiMicrophone,
  mdiSendOutline,
} from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import { ref, onMounted, onUnmounted, nextTick, getCurrentInstance } from '@vue/composition-api'
import { getVuetify } from '@core/utils'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import chatStoreModule from './chatStoreModule'
import useChat from './useChat'
import { foundryRequest } from '@/config/authConfig';

// Local Components
import ChatSystemSidebarContent from './ChatSystemSidebarContent.vue'
import ChatLeftSidebarContent from './ChatLeftSidebarContent.vue'
import ChatLog from './ChatLog.vue'
import ChatWelcome from './ChatWelcome.vue'
import ConversationDialog from './ConversationDialog.vue'
import Alert from '@/views/components/alert/Alert.vue'

export default {
  components: {
    PerfectScrollbar,
    ChatLog,
    Alert,

    // Local Components
    ChatSystemSidebarContent,
    ChatLeftSidebarContent,
    ChatWelcome,
    ConversationDialog
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const loading = ref(false)
    const convoLoaded = ref(true)
    const convoHistoryLoaded = ref(false)
    const isConvoSystemDialog = ref(false)
    const isConvoSystemDrawerActive = ref(false)

    const convoDialog = ref(false)
    const { 
      resolveAvatarBadgeVariant,
      resolveBotIconVariant,
      chatBotCreationLoading,
      convoSystemLoading} = useChat()
    const $vuetify = getVuetify()

    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 374 })

    // * Store module Registration
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    })

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    // ————————————————————————————————————
    //* ——— User Profile Sidebar
    // ————————————————————————————————————

    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref(JSON.parse(localStorage.getItem('user')))


    // ————————————————————————————————————
    //* ——— Chats & Contacts
    // ————————————————————————————————————
    const chatsContacts = ref([])

    const fetchConversations = async () => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        convoHistoryLoaded.value = false
      const email =  profileUserDataMinimal.value.email
      store.dispatch('app-chat/fetchConversations', {id:email})
        .then(response => {
          const { data } = response
          chatsContacts.value = data.conversations
          convoHistoryLoaded.value = true
          store.commit('comp-alert/SET_MESSAGE', {})
        })
        .catch(error => {
            store.commit('comp-alert/SET_MESSAGE', error.response.data)
        })
    }

    const addConversation = async (payload) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      chatBotCreationLoading.value = true
      store.dispatch('app-chat/addConversation', {payload}).then(response => {
        const { data } = response
        activeChat.value = data
        openChatOfConvo(data.id)
        chatBotCreationLoading.value = false
        convoDialog.value = false
        fetchConversations()
      })
    }

    
    const updateConversation = async (payload) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      convoSystemLoading.value = true
      store.dispatch('app-chat/updateConversation', {payload}).then(response => {
        const { data } = response
        activeChat.value = data
        isConvoSystemDrawerActive.value = false
        convoSystemLoading.value = false
        openChatOfConvo(data.id)
      })
    }


    // ————————————————————————————————————
    //* ——— Single Chat
    // ————————————————————————————————————

    const activeChat = ref({})
    const chatInputMessage = ref('')
    const openChatOfConvo = convoId => {
      // Reset send message input value
      chatInputMessage.value = ''
      convoLoaded.value = false
      store.dispatch('app-chat/getChat', { convoId }).then(response => {
        activeChat.value = response.data
        convoLoaded.value = true
        if (response.data.chats.length == 0 && !response.data.system) {
          isConvoSystemDialog.value = true
        }
        // Set unseenMsgs to 0
        // const contact = chatsContacts.value.find(c => c.id === convoId)
        // if (contact) contact.chat.unseenMsgs = 0

        // Scroll to bottom
        nextTick(() => {
          scrollToBottomInChatLog()
        })
      })

      // if smAndDown =>  Close Chat & Contacts left sidebar
      if ($vuetify.breakpoint.smAndDown) isLeftSidebarOpen.value = false
    }

    const sendMessage = () => {
      if (!chatInputMessage.value) return
      const payload = {
        conversation: activeChat.value.id,
        // eslint-disable-next-line no-use-before-define
        role: 'user',//profileUserDataMinimal.value.id,
        content: chatInputMessage.value
      }
      
      activeChat.value.chats.push(payload)
      scrollToBottomInChatLog()

      chatInputMessage.value = ''
      loading.value = true
      
      
      store.dispatch('app-chat/sendMessage', {payload})
        .then(response => {
          const { data } = response

          // ? If it's not undefined => New chat is created (Contact is not in list of chats)
          // if (chat !== undefined) {
          //   activeChat.value = { chat, contact: activeChat.value.contact }
          //   chatsContacts.value.push({
          //     ...activeChat.value.contact,
          //     chat: {
          //       id: chat.id,
          //       lastMessage: newMessageData,
          //       unseenMsgs: 0,
          //     },
          //   })
          // } else {
            // Add message to log
            activeChat.value.chats.push(data)
          // }

          // Reset send message input value
          chatInputMessage.value = ''
          loading.value = false
          store.commit('comp-alert/SET_MESSAGE', {})
          fetchConversations()

          // Set Last Message for active contact
          // const contact = chatsContacts.value.find(c => c.id === activeChat.value.contact.id)
          // contact.chat.lastMessage = newMessageData

          // Scroll to bottom
          nextTick(() => {
            scrollToBottomInChatLog()
          })
      }).catch(error => {
          loading.value = false
          if (error.response) {
            store.commit('comp-alert/SET_MESSAGE', error.response.data)
          } else {
            store.commit('comp-alert/SET_MESSAGE', error)
          }
      })
    }

    // ————————————————————————————————————
    //* ——— Active Chat
    // ————————————————————————————————————


    // Open Sidebar in smAndDown when "start conversation" is clicked
    const startConversation = () => {
      convoDialog.value = true
      if ($vuetify.breakpoint.mdAndUp) return
      isLeftSidebarOpen.value = true
    }

    // Perfect scrollbar options
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }


    // ————————————————————————————————————
    //* ——— Create Conversation
    // ————————————————————————————————————

    onMounted(() => {

      fetchConversations()
    })

    return {
      // useChat
      resolveAvatarBadgeVariant,
      resolveBotIconVariant,
      isConvoSystemDrawerActive,

      // Layout
      isLeftSidebarOpen,
      contentStyles,

      // Templare ref
      refChatLogPS,

      // User Profile Sidebar
      profileUserDataMinimal,

      // Chat & Contacts
      chatsContacts,
      convoLoaded,
      convoHistoryLoaded,
      isConvoSystemDialog,

      // Single Chat
      activeChat,
      chatInputMessage,
      openChatOfConvo,
      sendMessage,

      // startConversation
      convoDialog,
      startConversation,
      addConversation,
      updateConversation,
      loading,

      // Perfect Scrollbar
      perfectScrollbarOptions,

      // Filter
      avatarText,

      icons: {
        mdiMenu,
        mdiMessageTextOutline,
        mdiPhone,
        mdiVideoOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiAttachment,
        mdiMicrophone,
        mdiSendOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/chat.scss';
</style>
