<template>
  <div class="h-full" >
    <div class="drawer-header d-flex align-center mb-4">
      <span class="font-weight-semibold text-base text--primary">Set System behavior {{ convo.id }}</span>
      
      
      <v-btn
        icon
        small
        @click="$emit('update:is-convo-system-drawer-active',false)"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
      <v-form
        ref="refConvoSystemEventHandlerForm"
        class="px-5 pt-3 pb-10"
        @submit.prevent="handleFormSubmit"
      >
        <v-textarea
          v-model="convoLocal.system"
          label="System"
          placeholder="You are a helpful assistant."
          outlined
          :rules="[validators.required]"
          hide-details="auto"
          dense
          rows="25"
          :readonly="convo.system ? true : false"
          class="mb-4"
        ></v-textarea>
        
        <div v-if="convo.system ? false : true">
          <v-btn
            color="primary"
            class="me-3"
            type="submit"
            :loading="convoSystemLoading"
            @click.prevent="handleFormSubmit"
          >
            Add
          </v-btn>
          <v-btn
            outlined
            @click="resetConvoLocal"
          >
            Reset
          </v-btn>
        </div>
        
        
      </v-form>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted } from '@vue/composition-api'

import { required, } from '@core/utils/validation'
import {stringToHslColor } from '@core/utils'
import { avatarText } from '@core/utils/filter'
import { mdiClose } from '@mdi/js'

import useChat from './useChat'

export default {
  props: {
    convo: {
      type: Object,
      required: true,
    },
    isConvoSystemDrawerActive: {
        type:Boolean,
        required:true
    }
  },
  setup(props, { emit }) {
    // ————————————————————————————————————
    //* ——— Template Refs
    // ————————————————————————————————————

    const refConvoSystemEventHandlerForm = ref(null)
    

    // ————————————————————————————————————
    //* ——— Local Event
    // ————————————————————————————————————

    const convoLocal = ref(JSON.parse(JSON.stringify(props.convo)))
    const {convoSystemLoading} = useChat()


    const resetConvoLocal = () => {
      convoLocal.value = JSON.parse(JSON.stringify(props.convo))

      if (convoLocal.value.system) {
        refConvoSystemEventHandlerForm.value.validate()
      } else {
        // Reset Validation
        refConvoSystemEventHandlerForm.value.reset()
      }
    }

    // ————————————————————————————————————
    //* ——— Form
    // ————————————————————————————————————

    

    const handleFormSubmit = () => {
      const isFormValid = refConvoSystemEventHandlerForm.value.validate()
      if (!isFormValid) return

      const convoData = {}
      convoData.id = convoLocal.value.id
      convoData.system = convoLocal.value.system
      emit('update-convo', convoData)
    }


    // ————————————————————————————————————
    //* ——— Form Validator
    // ————————————————————————————————————

    watch(
      () => props.convo,
      () => {
        resetConvoLocal()
      },
      { deep: true },
    )

    watch(
      () => props.isConvoSystemDrawerActive,
      () => {
        resetConvoLocal()
      }
    )

    return {
      // Template Refs
      refConvoSystemEventHandlerForm,

      // Local Event
      convoLocal,
      resetConvoLocal,
      handleFormSubmit,
      convoSystemLoading,


      stringToHslColor,
      avatarText,
      
      // Field Validators
      validators: {
        required,
      },

      // Icons
      icons: {
        mdiClose
      },
    }
  },
}
</script>

<style lang="scss">

.ps-calendar-event-handler {
  height: calc(100% - 44px - 24px - 2px);

  // Fixes: if chips row >2 => Create enough space between chips row & avoid first row chip touching the label
  // Related Issue: https://github.com/vuetifyjs/vuetify/issues/13107
}

  .v-menu__content:not(.list-style) .v-list.v-list--dense .v-list-item {
    min-height: 3rem !important;
  }
</style>
